
// userMaterial has an embeddable link, e.g. YouTube videos
export function hasEmbeddableLink(userMaterial) {
  return userMaterial.href && userMaterial.videoId;
}

// userMaterial has a link to be opened in a seperate tab
export function hasExternalLink(userMaterial) {
  return userMaterial.href && !userMaterial.videoId;
}

// userMaterial is a shared assignment
export function isSharedAssignment(userMaterial) {
  return userMaterial.assignment && typeof userMaterial.userMaxScore !== 'number'
}

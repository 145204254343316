import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import Tools, { opaqueTools } from '../../../../../../../enums/tools';
import { TOOL_ICON_MAP } from '../../../../sidebar/tools/tool-icon-map';
import { colorObjects } from '../../../../../../../enums/colors';
import { getCurrentTool, getTools } from '../../../../../../../selectors/tools';
import ToolColorList from './ToolColorList';
import ToolSizeList from './ToolSizeList';
import { setColor, setSize } from '../../../../../../../actions/tools';
import { brushSizes } from '../../../../../../../enums/sizes';
import EraseAllAnnotationsButton from '../../../../sidebar/tools/erase-all-annotations-button';
import FlyOut from '../../Flyout';

export const TOOLS = [
  { tool: Tools.PENCIL, Icon: TOOL_ICON_MAP[Tools.PENCIL], toolTipKey: 'pencilTool.tooltip' },
  { tool: Tools.MARKER, Icon: TOOL_ICON_MAP[Tools.MARKER], toolTipKey: 'markerTool.tooltip' },
  { tool: Tools.TEXT_MARKER, Icon: TOOL_ICON_MAP[Tools.TEXT_MARKER], toolTipKey: 'textMarkerTool.tooltip' },
  { tool: Tools.TEXT_ANNOTATION, Icon: TOOL_ICON_MAP.annotation, toolTipKey: 'annotationTool.tooltip' },
  { tool: Tools.CLASSIC_ERASER, Icon: TOOL_ICON_MAP[Tools.CLASSIC_ERASER], toolTipKey: 'classic_eraserTool.tooltip' },
  { tool: Tools.SELECTION_ERASER, Icon: TOOL_ICON_MAP[Tools.SELECTION_ERASER], toolTipKey: 'selection_eraserTool.tooltip' },
];

const SIZEABLE_TOOLS = [Tools.PENCIL, Tools.MARKER, Tools.CLASSIC_ERASER];
const TOOLS_WITH_COLORS = [Tools.PENCIL, Tools.MARKER, Tools.TEXT_MARKER];
const TOOLS_DISMISSING_PANEL = [Tools.TEXT_ANNOTATION, Tools.SELECTION_ERASER];

export default function ToolsPanel({ onToolClick, onClose, onEraseAllAnnotations, onStopDrawing, isVisible, setIsVisible }) {
  const currentTool = useSelector(getCurrentTool);
  const currentToolConfig = useSelector(getTools)[currentTool];
  const { t } = useTranslation();

  const isOpaque = opaqueTools.includes(currentTool);
  const isCircled = currentTool === Tools.PENCIL || currentTool === Tools.CLASSIC_ERASER;

  const dispatch = useDispatch();

  function handleSizeClick(size) {
    dispatch(setSize(size));
  }

  function handleColorClick(color) {
    dispatch(setColor(color));
  }

  function handleToolClick(tool) {
    if (TOOLS_DISMISSING_PANEL.includes(tool)) onClose();
    onToolClick(tool);
  }

  return createPortal(
    <>
      {currentTool !== Tools.POINTER && (
        <button type="button" className="tools-panel__exit-drawing-mode" onClick={onStopDrawing}>
          {t('bottomBar.drawing.stopDrawing')}
        </button>
      )}
      <FlyOut visible={isVisible} setVisibility={setIsVisible}>
        <div className="tools-panel" data-testid="tools-panel">
          {SIZEABLE_TOOLS.includes(currentTool) && (
            <div className="tool-sizes-wrapper">
              <div>
                <ToolSizeList
                  sizes={Object.keys(brushSizes[currentTool])}
                  onSizeClick={handleSizeClick}
                  currentColor={currentToolConfig.color?.color}
                  currentSize={currentToolConfig.size}
                  isCircled={isCircled}
                />
                {!TOOLS_WITH_COLORS.includes(currentTool) && <div className="divider-line" />}
              </div>
            </div>
          )}
          {TOOLS_WITH_COLORS.includes(currentTool) && (
            <div className="tool-colors-wrapper">
              <div>
                {SIZEABLE_TOOLS.includes(currentTool) && <div className="divider-line" />}
                <ToolColorList
                  colors={Object.values(colorObjects)}
                  activeColor={currentToolConfig.color}
                  isOpaque={isOpaque}
                  isCircle={isCircled}
                  onColorClick={handleColorClick}
                  type="default"
                  renderTopDivider
                />
                <div className="divider-line" />
              </div>
            </div>
          )}
          <div className="tools-list" data-testid="tools-list">
            {TOOLS.map(({ tool, Icon, toolTipKey }) => (
              <button
                key={tool}
                type="button"
                onClick={() => handleToolClick(tool)}
                className={classNames('tools-list__tool', { 'tools-list__tool--active': currentTool === tool })}
                title={t(toolTipKey)}
              >
                <Icon />
              </button>
            ))}
            <EraseAllAnnotationsButton onAnnotationsDeleted={onEraseAllAnnotations} className="tools-list__tool" />
          </div>
        </div>
      </FlyOut>
    </>,
    document.body,
  );
}

ToolsPanel.propTypes = {
  isVisible: bool.isRequired,
  setIsVisible: func.isRequired,
  onToolClick: func.isRequired,
  onClose: func.isRequired,
  onEraseAllAnnotations: func.isRequired,
  onStopDrawing: func.isRequired,
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { node, string } from 'prop-types';
import EyeStrikethroughInSquareIcon from '@pelckmans/business-components/icons/EyeStrikethroughInSquare';
import EyeInSquareIcon from '@pelckmans/business-components/icons/EyeInSquare';
import { UserSettingsContext } from '../../../context/user-settings-context';
import ToolsEnum from '../../../../../enums/tools';
import { setCurrentTool } from '../../../../../actions/tools';

const { POINTER } = ToolsEnum;

export default function ToggleMarkingsButton({ className = undefined, showMessage = undefined, hideMessage = undefined }) {
  const { t } = useTranslation();
  const { showMarkings, setShowMarkings } = useContext(UserSettingsContext);

  const dispatch = useDispatch();

  const toggleMarkings = () => {
    dispatch(setCurrentTool(POINTER));
    setShowMarkings(!showMarkings);
  };

  return (
    <button type="button" onClick={toggleMarkings} title={showMarkings ? t('dock.buttons.hideMarkings.tooltip') : t('dock.buttons.showMarkings.tooltip')} className={className}>
      {showMarkings ? (
        <>
          <EyeStrikethroughInSquareIcon />
          {hideMessage}
        </>
      ) : (
        <>
          <EyeInSquareIcon />
          {showMessage}
        </>
      )}
    </button>
  );
}

ToggleMarkingsButton.propTypes = {
  className: string,
  showMessage: node,
  hideMessage: node,
};

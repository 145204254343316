import { node } from 'prop-types';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsMobileWidth } from '../../../actions/navigation';

export const MobileSizeContext = createContext({
  isMobileHeight: false,
  setMaxDesktopHeight: () => {},
  isMobileWidth: false,
  setMaxDesktopWidth: () => {},
});

// const classNames = {
//   mobile: 'mobile',
//   mobileWidth: 'mobile--width',
//   mobileHeight: 'mobile--height',
// };

export function MobileSizeProvider({ children }) {
  const [maxDesktopWidth, setMaxDesktopWidth] = useState(0);
  const [maxDesktopHeight, setMaxDesktopHeight] = useState(0);

  const [isMobileHeight, setMobileHeight] = useState(false);
  const [isMobileWidth, setMobileWidth] = useState(false);

  const dispatch = useDispatch();

  const determineMobileFlags = useCallback(() => {
    if (window.innerWidth < 544) {
      setMobileWidth(true);
      setMobileHeight(true);
    } else {
      if (window.innerWidth < maxDesktopWidth) {
        setMobileWidth(true);
      } else {
        setMobileWidth(false);
      }

      if (window.innerHeight < maxDesktopHeight) {
        setMobileHeight(true);
      } else {
        setMobileHeight(false);
      }
    }
  }, [maxDesktopHeight, maxDesktopWidth]);

  useEffect(() => {
    determineMobileFlags();
  }, [determineMobileFlags]);

  // useEffect(() => {
  //   window.addEventListener('resize', determineMobileFlags);

  //   return () => {
  //     window.removeEventListener('resize', determineMobileFlags);
  //   };
  // }, [determineMobileFlags]);

  useEffect(() => {
    dispatch(setIsMobileWidth(isMobileWidth));
  }, [dispatch, isMobileWidth]);

  // useLayoutEffect(() => {
  //   if (isMobileWidth || isMobileHeight) {
  //     document.body.classList.add(classNames.mobile);

  //     if (isMobileWidth) document.body.classList.add(classNames.mobileWidth);
  //     if (isMobileHeight) document.body.classList.add(classNames.mobileHeight);
  //   }

  //   if (!isMobileWidth && !isMobileHeight) {
  //     document.body.classList.remove('mobile');
  //   }
  //   if (!isMobileWidth) document.body.classList.remove(classNames.mobileWidth);
  //   if (!isMobileHeight) document.body.classList.remove(classNames.mobileHeight);
  // }, [isMobileHeight, isMobileWidth]);

  const value = {
    isMobileHeight,
    setMaxDesktopHeight,
    isMobileWidth,
    setMaxDesktopWidth,
  };

  return <MobileSizeContext.Provider value={value}>{children}</MobileSizeContext.Provider>;
}

MobileSizeProvider.propTypes = {
  children: node.isRequired,
};

// The scale on which the off-screen PDF is to be rendered.
export const PDF_SCALE = 2;
export const TRANSPARENT = 'rgba(0,0,0,0)';
export const PORTAAL_URL = process.env.PORTAAL_URL || 'http://localhost:8080/';
export const PORTAAL_API_URL = process.env.PORTAAL_API_URL || '';
export const DOCEO_TEMPLATE_URL = process.env.DOCEO_TEMPLATE_URL || 'http://localhost:8003/sso/:provider/:id';
export const DOCEO_OVERVIEW_URL = process.env.DOCEO_TEMPLATE_URL ? `${process.env.DOCEO_TEMPLATE_URL}/overview` : 'http://localhost:8003/sso/:provider/:id/overview';
export const DOCEO_COPY_URL = `${DOCEO_TEMPLATE_URL}/copy`;
export const LTI_API_URI = process.env.LTI_API_URI || 'https://txmcf4v4r4.execute-api.eu-central-1.amazonaws.com';
export const STUDIO_GROUP = process.env.STUDIO_GROUP || 'SO';
export const LANGUAGE = process.env.LANGUAGE || 'nl';
export const ANALYTICS_KEY = process.env.ANALYTICS_KEY || 'UA-81304517-2';

export const MEDIALINK_KIND = Object.freeze({
  EXTERNAL_MEDIA: 'external-media',
  EXERCISE_EDUMATIC: 'exercise-edumatic',
  EXERCISE_EDUHINT: 'exercise-eduhint',
  FILE: 'file',
  MINI_DIALOG: 'mini-dialog',
  WORD_LIST: 'word-list',
  MINI_SITE: 'mini-site',
});

export default {
  PDF_SCALE,
  TRANSPARENT,
};

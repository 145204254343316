import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import { func, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createNote } from '../../../../../../../actions/notes';
import useCloseDrawerOnMobile from '../../hooks/useCloseDrawerOnMobile';

export default function AddNoteButton({ dispatch, nodeId }) {
  const [t] = useTranslation();

  const handleClickAndCloseDrawer = useCloseDrawerOnMobile(() => dispatch(createNote(nodeId)));

  return (
    <div className="pbb-list__link-wrap">
      <button type="button" data-testid="add-note-button" onClick={handleClickAndCloseDrawer} className="pbb-list__link" title={t('drawer.notes.buttons.add_tooltip')}>
        <PlusInCircleIcon className="pbb-list__link-icon" />
        <span className="pbb-list__link-text">{t('drawer.notes.buttons.add')}</span>
      </button>
    </div>
  );
}

AddNoteButton.propTypes = {
  dispatch: func.isRequired,
  nodeId: string.isRequired,
};

import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { string, func, shape, bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import { brushSizes } from '../../../../../enums/sizes';
import Tools from '../../../../../enums/tools';

import { setSize } from '../../../../../actions/tools';
import { TOOL_ICON_MAP } from './tool-icon-map';
import EraseAllAnnotationsButton from './erase-all-annotations-button';

function Eraser({ isOpen = false, currentTool, onToolClick, options }) {
  const [lastSelectedEraser, setLastSelectedEraser] = useState(Tools.CLASSIC_ERASER);

  const dispatch = useDispatch();
  const [t] = useTranslation();

  function currentIcon() {
    if (lastSelectedEraser === Tools.CLASSIC_ERASER) return TOOL_ICON_MAP[Tools.CLASSIC_ERASER]({ className: 'pbb-sidebar__icon' });
    return TOOL_ICON_MAP[Tools.SELECTION_ERASER]({ className: 'pbb-sidebar__icon' });
  }

  return (
    <div className="pbb-tool-wrapper">
      <button
        type="button"
        onClick={() => onToolClick(lastSelectedEraser)}
        title={[Tools.CLASSIC_ERASER, Tools.SELECTION_ERASER].includes(currentTool) ? t(`${currentTool}Tool.tooltip`) : t('classic_eraserTool.tooltip')}
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === lastSelectedEraser, 'pbb-sidebar__button--selected': isOpen })}
      >
        {currentIcon()}
      </button>
      {isOpen && (
        <div className="pbb-sidebar__menu">
          <div className="pbb-menu__erasers">
            <button
              type="button"
              title={t('classic_eraserTool.tooltip')}
              onClick={() => {
                onToolClick(Tools.CLASSIC_ERASER);
                setLastSelectedEraser(Tools.CLASSIC_ERASER);
              }}
              className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === Tools.CLASSIC_ERASER })}
            >
              {TOOL_ICON_MAP[Tools.CLASSIC_ERASER]({ className: 'pbb-sidebar__icon' })}
            </button>
            <button
              type="button"
              title={t('selection_eraserTool.tooltip')}
              onClick={() => {
                onToolClick(Tools.SELECTION_ERASER);
                setLastSelectedEraser(Tools.SELECTION_ERASER);
              }}
              className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === Tools.SELECTION_ERASER })}
            >
              {TOOL_ICON_MAP[Tools.SELECTION_ERASER]({ className: 'pbb-sidebar__icon' })}
            </button>
            <EraseAllAnnotationsButton className="pbb-sidebar__button" onAnnotationsDeleted={() => onToolClick(Tools.POINTER)} />
          </div>
          {currentTool === Tools.CLASSIC_ERASER && (
            <>
              <div className="divider-line" />
              <div className="pbb-menu__sizes pbb-menu__sizes--eraser">
                {Object.keys(brushSizes[Tools.CLASSIC_ERASER]).map(size => (
                  <button
                    key={size}
                    onClick={() => dispatch(setSize(size))}
                    type="button"
                    title={t(`sizes.eraser.${size}`)}
                    className={classNames(`size--${size}`, 'pbb-sidebar__button', {
                      'pbb-sidebar__button--active': options.size === size,
                    })}
                  >
                    <span className="indicator" />
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

Eraser.propTypes = {
  // Own Props
  isOpen: bool,
  currentTool: string,
  onToolClick: func.isRequired,
  options: shape({
    type: string,
    size: string,
  }).isRequired,
};

export default Eraser;

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMenu, MenuGroup, MenuItem } from '@pelckmans/business-components/components/action-menu';
import { Button } from '@pelckmans/business-components/components/button';
import AnnotationSetIcon from '@pelckmans/business-components/icons/AnnotationSet';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { func } from 'prop-types';
import { openAnnotationSetsManagementModal } from '../../../../../actions/dialog';
import { getActiveAnnotationSet, getAnnotationSets } from '../../../../../selectors/annotationSets';
import { setActiveAnnotationSet } from '../../../../../actions/annotationSets';
import { MobileSizeContext } from '../../../context/MobileSizeContext';
import Flyout from '../../bottomBar/components/Flyout';
import ToggleMarkingsButton from './ToggleMarkingsButton';

function AnnotationSetsFlyout({ onItemClick, onManageAnnotationsClick }) {
  const { t } = useTranslation();

  const [isFlyoutVisible, setVisibilityOfFlyout] = useState(false);

  const annotationSets = useSelector(getAnnotationSets);
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);

  const handleManagementClick = () => {
    setVisibilityOfFlyout(false);
    onManageAnnotationsClick();
  };

  return (
    <>
      <button type="button" title={t('dock.buttons.annotationSets.tooltip')} onClick={() => setVisibilityOfFlyout(!isFlyoutVisible)}>
        <AnnotationSetIcon data-testid="annotation-set-icon" />
      </button>
      {createPortal(
        <Flyout visible={isFlyoutVisible} setVisibility={setVisibilityOfFlyout} className="annotation-sets-menu">
          <>
            <div className="annotation-sets-menu__header">{t('dock.actionMenu.annotationSets.title')}</div>
            <div data-testid="annotation-sets" className="annotation-sets">
              {annotationSets.map(annotationSet => (
                <button
                  key={annotationSet.id}
                  type="button"
                  onClick={() => onItemClick(annotationSet)}
                  className={classNames('annotation-set', { active: activeAnnotationSet.id === annotationSet.id })}
                >
                  <AnnotationSetIcon />
                  <span>{annotationSet.name}</span>
                </button>
              ))}
            </div>
            <div>
              <Button size="small" onClick={handleManagementClick} title={t('dock.actionMenu.annotationSets.managementButton')}>
                {t('dock.actionMenu.annotationSets.managementButton')}
              </Button>
            </div>
            <ToggleMarkingsButton
              className="annotation-sets-menu__toggle-markings"
              showMessage={t('dock.actionMenu.annotationSets.show')}
              hideMessage={t('dock.actionMenu.annotationSets.hide')}
            />
          </>
        </Flyout>,
        document.body,
      )}
    </>
  );
}

AnnotationSetsFlyout.propTypes = {
  onItemClick: func.isRequired,
  onManageAnnotationsClick: func.isRequired,
};

function AnnotationSetsMenu({ onItemClick, onManageAnnotationsClick }) {
  const { t } = useTranslation();

  const annotationSets = useSelector(getAnnotationSets);
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);

  const { isMobileWidth } = useContext(MobileSizeContext);

  return (
    <ActionMenu
      title="Menu"
      menuClassName="dock-flyout-menu annotation-sets-menu"
      action={
        <button type="button" title={t('dock.buttons.annotationSets.tooltip')}>
          <AnnotationSetIcon data-testid="annotation-set-icon" />
        </button>
      }
      setDownOverflow
      boundingBoxPadding="10"
      portal
      align={isMobileWidth ? 'center' : 'start'}
    >
      <MenuItem data-testid="menu-title" className="annotation-sets-menu__header">
        {t('dock.actionMenu.annotationSets.title')}
      </MenuItem>
      <MenuGroup takeOverflow>
        {annotationSets.map(annotationSet => (
          <React.Fragment key={annotationSet.id}>
            <MenuItem
              className={classNames('dock-flyout-menu__item', { 'dock-flyout-menu__item--active': activeAnnotationSet.id === annotationSet.id })}
              onClick={() => onItemClick(annotationSet)}
              title={annotationSet.name}
            >
              <AnnotationSetIcon />
              {annotationSet.name}
            </MenuItem>
          </React.Fragment>
        ))}
      </MenuGroup>
      <MenuItem className="annotation-sets-menu__button" title="">
        <Button size="small" onClick={onManageAnnotationsClick} title={t('dock.actionMenu.annotationSets.managementButton')}>
          {t('dock.actionMenu.annotationSets.managementButton')}
        </Button>
      </MenuItem>
      {isMobileWidth && (
        <MenuItem>
          <ToggleMarkingsButton
            className="annotation-sets-menu__toggle-markings"
            showMessage={t('dock.actionMenu.annotationSets.show')}
            hideMessage={t('dock.actionMenu.annotationSets.hide')}
          />
        </MenuItem>
      )}
    </ActionMenu>
  );
}

AnnotationSetsMenu.propTypes = {
  onItemClick: func.isRequired,
  onManageAnnotationsClick: func.isRequired,
};

export default function AnnotationSetsButton() {
  const { isMobileWidth, isMobileHeight } = useContext(MobileSizeContext);

  const dispatch = useDispatch();

  const openManagementModal = () => {
    dispatch(openAnnotationSetsManagementModal());
  };

  const handleItemClick = annotationSet => {
    dispatch(setActiveAnnotationSet(annotationSet));
  };

  if (isMobileWidth && isMobileHeight) {
    return <AnnotationSetsFlyout onItemClick={handleItemClick} onManageAnnotationsClick={openManagementModal} />;
  }

  return <AnnotationSetsMenu onItemClick={handleItemClick} onManageAnnotationsClick={openManagementModal} />;
}

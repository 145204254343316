import { bool, func, node, shape, string } from 'prop-types';
import React from 'react';

import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../../../enums/analytics';
import ENTITY_TYPE from '../../../../../../../../enums/entityType';
import useAnalytics from '../../../../../../../../hooks/useAnalytics';
import { hasExternalLink, isSharedAssignment } from '../../../../../../../../utils/userMaterial';
import useCloseDrawerOnMobile from '../../../hooks/useCloseDrawerOnMobile';

function MaterialButton({ className, material, onMaterialClick, isTeacher, children }) {
  const materialType = material.kind ? ENTITY_TYPE.MEDIALINK : ENTITY_TYPE.USER_MATERIAL;
  const analytics = useAnalytics();

  const handleExternalLinkClick = useCloseDrawerOnMobile(() =>
    analytics.capture({
      eventType: materialType === ENTITY_TYPE.USER_MATERIAL ? ANALYTICS_EVENT_TYPES.USER_MATERIAL_ACCESSED : ANALYTICS_EVENT_TYPES.MEDIALINK_ACCESSED,
      objectId: material.id,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    }),
  );

  return hasExternalLink(material) && !(!isTeacher && isSharedAssignment(material)) ? (
    <a className={className} href={material.href} onClick={handleExternalLinkClick} title={material.name} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <button className={className} type="button" onClick={onMaterialClick} title={material.name}>
      {children}
    </button>
  );
}

MaterialButton.propTypes = {
  className: string,
  material: shape({
    id: string.isRequired,
    name: string.isRequired,
    kind: string,
    href: string,
  }).isRequired,
  onMaterialClick: func.isRequired,
  isTeacher: bool.isRequired,
  children: node.isRequired,
};

export default MaterialButton;

import React from 'react';

import { arrayOf, bool, func, number, oneOf, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { rgbToRgba } from '../../../../../../../utils/colors';

const emptyDefaultColor = 'rgba(214,214,214,1)';

function determineIndicatorColor(color, isOpaque) {
  if (!color) return emptyDefaultColor;

  return isOpaque ? rgbToRgba(color, 0.75) : color;
}

export default function ColorList({ colors, activeColor, isOpaque, isCircle, onColorClick, type, selectedColorOrder }) {
  const { t } = useTranslation();

  return (
    <div className="tool-colors" data-testid="tool-colors">
      {colors.map((colorValue = {}, index) => {
        const { id } = colorValue;

        const indicatorColor = determineIndicatorColor(colorValue?.color, isOpaque);
        const isActive = selectedColorOrder === index || id === activeColor?.id;
        const title = type === 'default' ? t(`colors.${id}`) : t('colorPicker.customSlot.title');
        const key = `${type}-color-${index}`;

        return (
          <button
            title={title}
            onClick={() => onColorClick({ ...colorValue, order: index })}
            key={key}
            className={classNames('tool-colors__color', { 'tool-colors__color--active': isActive })}
            data-testid={`${type}-color-${index}`}
            type="button"
          >
            <span
              data-testid="indicator"
              className={classNames('indicator', { 'indicator--circled': isCircle })}
              style={{ backgroundColor: indicatorColor, outline: isActive ? `2px solid ${indicatorColor}` : 'none' }}
            >
              {!colorValue && '+'}
            </span>
          </button>
        );
      })}
    </div>
  );
}

const customColorShape = shape({
  id: oneOfType([string, number]).isRequired,
  color: string.isRequired,
  order: number.isRequired,
});

ColorList.propTypes = {
  activeColor: customColorShape,
  selectedColorOrder: number,
  colors: arrayOf(customColorShape).isRequired,
  onColorClick: func.isRequired,
  isOpaque: bool,
  isCircle: bool,
  type: oneOf(['default', 'custom']).isRequired,
};

import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import { string, shape, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from '@pelckmans/business-components/components/text-annotations/constants';
import MedialinkItem from '../../sidebar/drawer/material/components/medialink/MedialinkItem';
import useClickOutside from '../../../../../hooks/useClickOutside';

import { getMedialinksChooserData } from '../../../../../selectors/dialogs';
import { closeMediaLinkChooser } from '../../../../../actions/dialog';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../enums/analytics';

export const OFFSET = 40;
const DOCK_HEIGHT = 50;

export const getPositioningData = (dimensions, mousePosition) => {
  const style = {
    top: mousePosition.y - (dimensions.height || 0) - OFFSET,
    left: mousePosition.x - OFFSET,
  };

  let className = 'on-bottom';

  const canShowOnTop = mousePosition.y - dimensions.height - OFFSET > 0;
  const canShowOnLeft = mousePosition.x - dimensions.width - OFFSET - SIDEBAR_WIDTH > 0;
  const canShowOnBottom = mousePosition.y + dimensions.height + OFFSET + DOCK_HEIGHT < window.innerHeight;
  const canShowOnRight = mousePosition.x + dimensions.width + OFFSET < window.innerWidth;

  if (canShowOnTop) {
    if (mousePosition.x + dimensions.width > window.innerWidth) {
      style.left = mousePosition.x - dimensions.width + OFFSET;
      className = 'on-bottom-right';
    }
  }

  if (!canShowOnTop && canShowOnLeft) {
    style.left = mousePosition.x - dimensions.width - OFFSET;
    style.top = mousePosition.y - OFFSET;
    className = 'on-right';
  }

  if (!canShowOnTop && !canShowOnLeft && canShowOnRight) {
    style.left = mousePosition.x + OFFSET;
    style.top = mousePosition.y - OFFSET;
    className = 'on-left';
  }
  if (!canShowOnTop && !canShowOnLeft && !canShowOnRight && canShowOnBottom) {
    style.top = mousePosition.y + OFFSET;
    style.left = mousePosition.x - dimensions.width + OFFSET;
    className = 'on-top-right';

    if (mousePosition.x + dimensions.width - OFFSET < window.innerWidth) {
      style.left = mousePosition.x - OFFSET;
      className = 'on-top';
    }
  }

  return [style, className];
};

function MedialinksChooser(props) {
  const { mousePosition, linkAreaId } = props;
  const dispatch = useDispatch();
  const { title, medialinks } = useSelector(getMedialinksChooserData);

  const containerRef = useRef(null);

  const closeChooser = useCallback(() => {
    dispatch(closeMediaLinkChooser(linkAreaId));
  }, [dispatch, linkAreaId]);

  useClickOutside(containerRef, closeChooser);

  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const { clientWidth: width, clientHeight: height } = containerRef.current;
      setDimensions({ width, height });
    }
  }, [containerRef, medialinks]);

  const [positionStyle, arrowClass] = useMemo(() => getPositioningData(dimensions, mousePosition), [dimensions, mousePosition]);

  const renderHidden = !dimensions.width || !dimensions.height;

  return (
    <div
      ref={containerRef}
      className="pbb-popover pbb-popover--none"
      data-testid="medialink-chooser"
      style={{
        transform: 'none',
        visibility: renderHidden ? 'hidden' : 'visible',
        zIndex: 9998,
        ...positionStyle,
      }}
    >
      <div className={`triangle-with-shadow ${arrowClass}`} />
      <div className="pbb-popover__title">
        <span className="pbb-popover__title-text">{title}</span>
        <button type="button" data-dismiss="pbb-modal" aria-label="Close" onClick={closeChooser} className="pbb-popover__close" data-testid="close">
          <i className="pbb-popover__title-icon icon-submodule-close" />
        </button>
      </div>
      <ul className="pbb-list pbb-list--borders">
        {medialinks && medialinks.map(medialink => <MedialinkItem key={medialink.id} medialink={medialink} subLocationId={ANALYTICS_EVENT_SUBLOCATIONS.LINKAREA} />)}
      </ul>
    </div>
  );
}

MedialinksChooser.propTypes = {
  linkAreaId: string.isRequired,
  mousePosition: shape({ x: number, y: number }),
};

export default MedialinksChooser;

import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import InfoIcon from '@pelckmans/business-components/icons/Info';
import ShareIcon from '@pelckmans/business-components/icons/Share';
import { bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';

import { openMaterialAssignmentModal, openUserMaterial } from '../../../../../../../../actions/dialog';
import { toggleUserMediaFavorite } from '../../../../../../../../actions/userMedia';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../../../../enums/analytics';
import featureToggle from '../../../../../../../../enums/feature-toggle';
import Role from '../../../../../../../../enums/roles';
import { getTeacherFeaturesEnabledFor } from '../../../../../../../../selectors/digibooks';
import { getModuleById } from '../../../../../../../../selectors/module';
import { getRoles } from '../../../../../../../../selectors/user';
import api from '../../../../../../../../services/api';
import { getFeatureToggleFor } from '../../../../../../../../utils/feature-toggle';
import { getFileType } from '../../../../../../../../utils/filetype';
import { hasEmbeddableLink, isSharedAssignment } from '../../../../../../../../utils/userMaterial';
import FavoriteButton from '../../../../../buttons/FavoriteButton';
import OptionsButton from '../../../../../buttons/OptionsButton';
import DrawerOptionsMenu from '../../../DrawerOptionsMenu';
import useCloseDrawerOnMobile from '../../../hooks/useCloseDrawerOnMobile';
import MaterialInfo from '../MaterialInfo';
import MaterialButton from './MaterialButton';
import UserMaterialPropType from './user-material-prop-type';
import UserMediaPreviewModal from './UserMaterialPreviewModal';

const UserMaterialListItem = ({ userMaterial, isOwner, openOptionsModal, isTeacher, moduleId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isPreviewModalActive, setIsPreviewModalActive] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState();

  const toggleFavorite = evt => {
    evt.preventDefault();
    dispatch(toggleUserMediaFavorite(userMaterial));
  };

  const openMaterialAndCloseDrawer = useCloseDrawerOnMobile(async evt => {
    evt.preventDefault();

    if (!isTeacher && isSharedAssignment(userMaterial)) {
      dispatch(openMaterialAssignmentModal({ ...userMaterial, moduleId: userMaterial.module }, 'user-material', userMaterial.assignment, userMaterial.owner));
    } else if (userMaterial.lti || hasEmbeddableLink(userMaterial)) {
      dispatch(openUserMaterial({ ...userMaterial, moduleId: userMaterial.module }, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL));
    } else if (userMaterial.file) {
      const { data: signedUrls } = await api.get(`/studio/user/modules/${userMaterial.module}/user-material/${userMaterial.id}/signed-url`);

      const fileType = getFileType(userMaterial.file.mimeType);

      if (['video', 'audio', 'image'].includes(fileType) || signedUrls.previewUrl) {
        dispatch(openUserMaterial(userMaterial, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL, { signedUrls }));
        return;
      }

      setSelectedMedia({ ...userMaterial, signedUrls });
      setIsPreviewModalActive(true);
    }
  });

  const openOptionsModalAndCloseDrawer = useCloseDrawerOnMobile(openOptionsModal);

  const module = useSelector(state => getModuleById(state, moduleId));
  const isSharingFeatureEnabled = getFeatureToggleFor(featureToggle.SHARING_MATERIAL);
  const sharingIsEnabled = isSharingFeatureEnabled && isOwner;
  const sharedOrAssignment = userMaterial.shared || userMaterial.assignment;
  const isLicensed = module.licenses.hasDigitalManualLicenseForTeacher;
  const showSharingTab = sharingIsEnabled && (isLicensed || sharedOrAssignment);
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);

  return (
    <>
      <li className="pbb-list__item" data-testid="user-media-item" data-id={userMaterial.id}>
        <div className="pbb-list__link-wrap">
          <MaterialButton className="pbb-list__link pbb-flex-wrap" material={userMaterial} onMaterialClick={openMaterialAndCloseDrawer} isTeacher={isTeacher}>
            <MaterialInfo material={userMaterial} isOwner={isOwner} materialType="user-material" />
          </MaterialButton>
          <div className="pbb-list__actions">
            <FavoriteButton onClick={toggleFavorite} isActive={userMaterial.isFavorite} className="pbb-list__action" />
            {teacherFeaturesEnabled ? (
              <DrawerOptionsMenu>
                {showSharingTab && (
                  <MenuItem onClick={() => openOptionsModalAndCloseDrawer(userMaterial, 'sharing')}>
                    <ShareIcon />
                    <span>{t('tabs.sharing')}</span>
                  </MenuItem>
                )}
                <MenuItem onClick={() => openOptionsModalAndCloseDrawer(userMaterial, 'info')}>
                  <InfoIcon />
                  <span>{t('tabs.info.title')}</span>
                </MenuItem>
                {isOwner && (
                  <MenuItem onClick={() => openOptionsModalAndCloseDrawer(userMaterial, 'delete')}>
                    <BinIcon />
                    <span>{t('tabs.delete')}</span>
                  </MenuItem>
                )}
              </DrawerOptionsMenu>
            ) : (
              <OptionsButton onClick={() => openOptionsModalAndCloseDrawer(userMaterial, 'info')} className="pbb-list__action" />
            )}
          </div>
        </div>
      </li>

      {isPreviewModalActive && createPortal(<UserMediaPreviewModal isOwner={isOwner} userMaterial={selectedMedia} onClose={() => setIsPreviewModalActive(false)} />, document.body)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    isTeacher: getRoles(state).includes(Role.TEACHER),
  };
}

UserMaterialListItem.propTypes = {
  userMaterial: UserMaterialPropType.isRequired,
  isOwner: bool.isRequired,
  openOptionsModal: func.isRequired,
  isTeacher: bool.isRequired,
  moduleId: string.isRequired,
};

export default connect(mapStateToProps)(UserMaterialListItem);

import { MaterialAssignmentModal as AssignmentModal } from '@pelckmans/business-components/components/material-assignment-modal';
import { format } from 'date-fns';
import { func, oneOf, shape, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { closeMaterialAssignmentModal, openMedialink, openMinisite, openUserMaterial } from '../../../../../actions/dialog';
import { postEvent } from '../../../../../api/analyticsApi';
import { STUDIO_GROUP } from '../../../../../constants/constants';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../enums/analytics';
import ENTITY_TYPE from '../../../../../enums/entityType';
import api, { tokenGetter } from '../../../../../services/api';
import { hasExternalLink } from '../../../../../utils/userMaterial';

const mapMaterials = materials => materials.map(({ entityType, ...m }) => ({ ...m, type: entityType }));

const MaterialAssignmentModal = ({ dispatch, material, sharedBy, assignment }) => {
  const [t] = useTranslation();
  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);

  // External link medialinks are supported openMedialink() in dialog.js
  const isExeternalLinkUserMaterial = material.entityType === ENTITY_TYPE.USER_MATERIAL && hasExternalLink(material);

  const translations = useMemo(
    () => ({
      close: t('actions.close'),
      cancel: t('actions.cancel'),
      confirm: t('actions.confirm'),
      open: t('actions.open'),
      title: t('materialAssignmentModal.title'),
      deadline: t('materialAssignmentModal.deadline', { date: format(new Date(assignment.to), 'dd/MM/yyyy') }),
      isDone: t('materialAssignmentModal.isDone'),
      task: t('materialAssignmentModal.task'),
    }),
    [assignment.to, t],
  );

  const handleClose = () => {
    dispatch(closeMaterialAssignmentModal());
  };

  const handleOpenLink = () =>
    postEvent({
      eventType: ANALYTICS_EVENT_TYPES.USER_MATERIAL_ACCESSED,
      objectId: material.id,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

  const handleOpenMaterial = async () => {
    if (material.entityType === ENTITY_TYPE.MEDIALINK) {
      if (material.kind === 'mini-site') return dispatch(openMinisite(material));

      return dispatch(openMedialink(material, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL));
    }

    if (!hasExternalLink(material)) {
      const signedUrls = material.file ? (await api.get(`/studio/user/modules/${material.moduleId}/user-material/${material.id}/signed-url`)).data : undefined;

      return dispatch(openUserMaterial(material, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL, { signedUrls }));
    }

    return false;
  };

  const handleSubmit = async error => {
    if (error instanceof Error) throw error;

    handleClose();
  };

  return (
    <AssignmentModal
      translations={translations}
      moduleId={material.moduleId}
      assignmentId={assignment.id}
      tokenAccessor={tokenAccessor}
      onClose={handleClose}
      onOpenMaterial={isExeternalLinkUserMaterial ? handleOpenLink: handleOpenMaterial}
      materials={mapMaterials([material])}
      onSubmit={handleSubmit}
      title={material.name}
      sharedBy={sharedBy}
      studioGroup={STUDIO_GROUP}
    />
  );
};

MaterialAssignmentModal.propTypes = {
  dispatch: func.isRequired,
  material: shape({
    id: string.isRequired,
    name: string.isRequired,
    moduleId: string.isRequired,
    entityType: oneOf(['user-material', 'medialink']).isRequired,
  }).isRequired,
  assignment: shape({
    id: string.isRequired,
    to: string.isRequired,
  }).isRequired,
  sharedBy: string.isRequired,
};

export default MaterialAssignmentModal;

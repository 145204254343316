import React, { useLayoutEffect, useState } from 'react';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ToolsPanel, { TOOLS } from './components/ToolsPanel';
import { getCurrentTool, getTools } from '../../../../../../selectors/tools';
import Tools from '../../../../../../enums/tools';
import { setCurrentTool } from '../../../../../../actions/tools';

const itemsCount = 1;

const CONFIGURABLE_TOOLS = TOOLS.map(({ tool }) => tool);

export default function ToolsButton({ calculateTotalWidth }) {
  const { t } = useTranslation();
  const currentTool = useSelector(getCurrentTool);
  const dispatch = useDispatch();

  const [selectedTool, setSelectedTool] = useState(CONFIGURABLE_TOOLS.includes(currentTool) ? currentTool : Tools.PENCIL);
  const currentColor = useSelector(getTools)[selectedTool]?.color?.color;

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  useLayoutEffect(() => {
    calculateTotalWidth(itemsCount);

    return () => {
      calculateTotalWidth(0);
    };
  }, [calculateTotalWidth]);

  function handleClick() {
    dispatch(setCurrentTool(selectedTool));
    setIsPanelOpen(!isPanelOpen);
  }

  function handleToolClick(tool) {
    setSelectedTool(tool);
    dispatch(setCurrentTool(tool));
  }

  const { Icon, toolTipKey } = TOOLS.find(({ tool }) => tool === selectedTool);

  function handleStopDrawing() {
    setIsPanelOpen(false);
    dispatch(setCurrentTool(Tools.POINTER));
  }

  const isDrawingActive = CONFIGURABLE_TOOLS.includes(currentTool);

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={classNames({ 'tools-panel-toggle-button--active': isDrawingActive })}
        style={isDrawingActive ? { '--accent-color': currentColor } : undefined}
        data-testid="toggle-panel-button"
        title={t(toolTipKey)}
      >
        <Icon />
      </button>
      <ToolsPanel
        isVisible={isPanelOpen}
        setIsVisible={setIsPanelOpen}
        onToolClick={handleToolClick}
        onClose={() => setIsPanelOpen(false)}
        onEraseAllAnnotations={handleStopDrawing}
        onStopDrawing={handleStopDrawing}
      />
    </>
  );
}

ToolsButton.propTypes = {
  calculateTotalWidth: func.isRequired,
};

import React from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ToolSizeList({ sizes, onSizeClick, currentColor, currentSize, isCircled }) {
  const { t } = useTranslation();

  return (
    <div className="tool-sizes" data-testid="tool-sizes">
      {sizes.map(size => (
        <button
          key={size}
          onClick={() => onSizeClick(size)}
          type="button"
          title={t(`sizes.tool.${size}`)}
          className={classNames('tool-sizes__size', `tool-sizes__size--${size}`, {
            'tool-sizes__size--active': currentSize === size,
          })}
        >
          <span
            className={classNames('indicator', { 'indicator--circled': isCircled, 'indicator--uncolored': !currentColor })}
            style={{ backgroundColor: currentColor }}
            data-testid="indicator"
          />
        </button>
      ))}
    </div>
  );
}

ToolSizeList.propTypes = {
  sizes: arrayOf(string).isRequired,
  onSizeClick: func.isRequired,
  currentColor: string,
  currentSize: string.isRequired,
  isCircled: bool,
};

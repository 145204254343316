import { DOCEO_COPY_URL, DOCEO_OVERVIEW_URL, DOCEO_TEMPLATE_URL } from '../constants/constants';

const DEFAULT_PROVIDER = 'pelckmans';

/**
 * @param {Object} slideSetInfo The slide set info with its id, versionId and state
 * @param {boolean} [isEditMode] Passing a boolean allows you to navigate to the overview/edit page, undefined goes to presentation mode
 * @param {string} [eventSubLocation] Allow doceo to handle the navigation event since we don't have slide/link information
 */
export const buildDoceoUrl = ({ id, versionId, state }, isEditMode, eventSubLocation) => {
  const provider = localStorage.getItem('p-provider') || DEFAULT_PROVIDER;

  const query = new URLSearchParams();

  if (isEditMode) query.set('edit', isEditMode);
  if (eventSubLocation) query.set('origin', eventSubLocation);
  if (state !== 'PUBLISHED' && versionId) query.set('versionId', versionId);

  const baseUrl = isEditMode === undefined ? DOCEO_TEMPLATE_URL : DOCEO_OVERVIEW_URL;
  return `${baseUrl.replace(':provider', provider).replace(':id', id)}?${query}`;
};

export const buildNewSlideSetUrl = (moduleId, nodeId, eventSubLocation) => {
  const provider = localStorage.getItem('p-provider') || DEFAULT_PROVIDER;
  const query = new URLSearchParams({
    origin: eventSubLocation,
  });

  if (nodeId) query.set('nodeId', nodeId);
  if (moduleId) query.set('moduleId', moduleId);

  return `${DOCEO_TEMPLATE_URL.replace(':provider', provider).replace(':id', 'new')}?${query}`;
};

export const buildDoceoCopyUrl = (id, versionId, moduleId, nodeId, eventSubLocation) => {
  const provider = localStorage.getItem('p-provider') || DEFAULT_PROVIDER;
  const query = new URLSearchParams({
    versionId,
    moduleId,
    nodeId,
    origin: eventSubLocation,
  });

  return `${DOCEO_COPY_URL.replace(':provider', provider).replace(':id', id)}?${query}`;
};


// Try to extract the videoId and start parameter from a youtube link
export function tryParseYoutubeVideoEmbedParams(uri) {
  try {
    const url = new URL(uri);
    const urlParams = new URLSearchParams(url.search);
    let videoId = null;
    let start = null;

    switch (url.host) {
      case 'youtu.be': {
        // self created link (example: https://youtu.be/M5ORMgl_KPE?t=6 )
        videoId = url.pathname.substr(1);
        break;
      }
      case 'www.youtube.com': // copied from browser (example: https://www.youtube.com/watch?v=M5ORMgl_KPE&feature=youtu.be&t=6 )
      case 'm.youtube.com': {
        // copied from mobile browser (example: https://m.youtube.com/watch?v=M5ORMgl_KPE&feature=youtu.be&t=6 )
        videoId = urlParams.get('v');
        break;
      }
      default:
    }

    if (videoId) start = urlParams.get('t');

    return {
      videoId: videoId || null,
      start: start || null,
    };
  } catch (e) {
    return {
      videoId: null,
      start: null,
    };
  }
}

import React, { useState } from 'react';
import BinInSquareIcon from '@pelckmans/business-components/icons/BinInSquare';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import Confirmation from '../../dialogs/confirmation';
import { getPlayerMode } from '../../../../../selectors/player';
import PlayerMode from '../../../../../enums/playerMode';
import { getPageNumbersToShow } from '../../../../../selectors/rendering';
import { getTotalPagesForDigibook } from '../../../../../selectors/digibooks';
import { calculateSpreadForPageNumbers } from '../../../../../utils/calculateSpreadForPageNumbers';
import { deleteMarkingsForSinglePage, deleteMarkingsForSpread } from '../../../../../actions/tools';
import MarkingPosition from '../../../../../enums/markingPosition';

function EraseAllAnnotationsButton({ onAnnotationsDeleted, className }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [confirmationShown, setConfirmationShown] = useState(false);

  const playerMode = useSelector(getPlayerMode) || PlayerMode.BOOK;
  const pageNumbersToShow = useSelector(getPageNumbersToShow) || [];
  const totalPages = useSelector(getTotalPagesForDigibook);

  const clearAllEraser = () => {
    if (playerMode !== PlayerMode.WHITEPAGE) {
      const spread = calculateSpreadForPageNumbers(pageNumbersToShow, totalPages);
      const allPagesVisible = spread.length === pageNumbersToShow.length;
      if (allPagesVisible) {
        dispatch(deleteMarkingsForSpread(spread));
      } else if (spread[0] === pageNumbersToShow[0]) {
        dispatch(deleteMarkingsForSinglePage(spread, MarkingPosition.LEFT));
      } else {
        dispatch(deleteMarkingsForSinglePage(spread, MarkingPosition.RIGHT));
      }
    }
    document.dispatchEvent(new CustomEvent('erase-all-clicked'));
    setConfirmationShown(false);
    onAnnotationsDeleted();
  };

  return (
    <>
      <button type="button" title={t('eraser.clearAll.tooltip')} onClick={() => setConfirmationShown(true)} className={classNames({ [className]: !!className })}>
        <BinInSquareIcon className="pbb-sidebar__icon" />
      </button>
      {confirmationShown && (
        <Confirmation
          title={t('eraser.clearAll.title')}
          icon="icon-bb-eraser-all"
          message={t('eraser.clearAll.message')}
          confirmationText={t('eraser.clearAll.button')}
          confirmationClassName="pbb-btn--danger"
          cancellationText={t('confirmation.cancel')}
          onConfirm={clearAllEraser}
          onCancel={() => setConfirmationShown(false)}
        />
      )}
    </>
  );
}

EraseAllAnnotationsButton.propTypes = {
  onAnnotationsDeleted: func.isRequired,
  className: string,
};

export default EraseAllAnnotationsButton;

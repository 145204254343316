import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCurrentDigibook, getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { buildNewSlideSetUrl } from '../../../../../../utils/buildDoceoUrl';
import NotLicensedModal from '../../../dialogs/NotLicensedModal';
import useCloseDrawerOnMobile from '../hooks/useCloseDrawerOnMobile';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../../enums/analytics';

function AddButton({ text, toolTip, nodeId }) {
  const moduleId = useSelector(getCurrentModuleId);
  const digibook = useSelector(getCurrentDigibook);

  const { t } = useTranslation();

  const [notLicensedVisible, setNotLicensedVisible] = useState(false);
  const licensed = digibook.teacherDigitalManualLicense;

  const closeDrawer = useCloseDrawerOnMobile();

  return (
    <li className="pbb-list__item">
      {notLicensedVisible &&
        createPortal(
          <NotLicensedModal title={t('notLicensed.accessFeature.title')} message={t('notLicensed.accessFeature.message')} onClose={() => setNotLicensedVisible(false)} />,
          document.body,
        )}
      <div className="pbb-list__link-wrap">
        {licensed ? (
          <a
            href={buildNewSlideSetUrl(moduleId, nodeId, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL)}
            onClick={closeDrawer}
            className="pbb-list__link"
            title={toolTip}
            target="_blank"
            rel="noreferrer"
          >
            <PlusInCircleIcon className="pbb-list__link-icon" />
            <span className="pbb-list__link-text">{text}</span>
          </a>
        ) : (
          <button type="button" onClick={() => setNotLicensedVisible(true)} className="pbb-list__link" title={toolTip}>
            <PlusInCircleIcon className="pbb-list__link-icon" />
            <span className="pbb-list__link-text">{text}</span>
          </button>
        )}
      </div>
    </li>
  );
}

AddButton.propTypes = {
  text: string.isRequired,
  toolTip: string.isRequired,
  nodeId: string.isRequired,
};

export default AddButton;
